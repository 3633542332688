<template>
  <div class="page">
    <div class="search-box section" style="overflow: visible">
      <el-form
          ref="query"
          :inline="true"
          :model="query"
          class="demo-form-inline"
          size="medium"
      >
        <el-form-item prop="parkingLotId">
          <SuggestParking v-model="query.parkingLotId" style="width: 260px"/>
        </el-form-item>
        <el-form-item prop="phone">
          <el-input
              v-model="query.phone"
              placeholder="根据手机号码搜索"
              style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input
              v-model="query.code"
              placeholder="订单编号"
              style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item prop="leaseInfoName">
          <el-input
              v-model="query.leaseInfoName"
              placeholder="套餐名称"
              style="width: 260px"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item prop="status">
          <el-select
            v-model="query.status"
            placeholder="请选择-执行状态"
            style="width: 260px"
          >
            <el-option label="正常" value="0"></el-option>
            <el-option label="关闭" value="1"></el-option>
            <el-option label="手动关闭" value="2"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item prop="auditStatus">
          <el-select
              v-model="query.auditStatus"
              :popper-append-to-body="false"
              placeholder="请选择-订单状态"
              style="width: 260px"
          >
            <el-option label="正常" value="0"></el-option>
            <el-option label="审核中" value="1"></el-option>
            <el-option label="审核不通过" value="2"></el-option>
            <el-option label="已过期" value="3"></el-option>
            <el-option label="已停用" value="4"></el-option>
            <el-option label="待生效" value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="payStatus">
          <el-select
              v-model="query.payStatus"
              :popper-append-to-body="false"
              placeholder="请选择-付款状态"
              style="width: 260px"
          >
            <el-option label="已付款" value="0"></el-option>
            <el-option label="未付款" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="addWay">
          <el-select
              v-model="query.addWay"
              :popper-append-to-body="false"
              placeholder="请选择-添加途径"
              style="width: 260px"
          >
            <el-option label="人工添加" value="0"></el-option>
            <el-option label="小程序添加" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="vehicleId">
          <!-- <el-input
            v-model="query.licenseNumber"
            placeholder="根据车牌号码搜索"
            style="width: 260px"
          ></el-input> -->
          <NumplateSuggest v-model="query.vehicleId" style="width: 260px"/>
        </el-form-item>
        <!-- <el-form-item prop="optionalInformation">
          <el-input
            v-model="query.optionalInformation"
            placeholder="选填信息"
            prefix-icon=""
            style="width: 260px"
          ></el-input>
        </el-form-item> -->
        <!-- <el-form-item prop="leaseOrderId">
          <el-input
            v-model="query.leaseOrderId"
            placeholder="长租订单编号"
            prefix-icon=""
            style="width: 260px"
          ></el-input>
        </el-form-item> -->
        <!-- <el-form-item prop="packageType">
          <el-select
            v-model="query.packageType"
            placeholder="请选择-套餐类型"
            style="width: 260px"
            :popper-append-to-body="false"
          >
            <el-option label="单车场" value="0"></el-option>
            <el-option label="多车场" value="1"></el-option>
            <el-option label="临时长租" value="2"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item prop="timeType">
          <div class="xxd-input__wrap">
            <el-select v-model="query.timeType" placeholder="时间范围">
              <el-option label="生效时间" value="0"></el-option>
              <el-option label="到期时间" value="1"></el-option>
              <el-option label="创建时间" value="2"></el-option>
            </el-select>
            <el-date-picker
                v-model="query.searchDate"
                align="right"
                end-placeholder="结束日期"
                range-separator="至"
                start-placeholder="开始日期"
                type="daterange"
                value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="resetQueryForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box section">
      <div class="op-box">
        <div class="op-content">
          共<span class="total-size">{{ total }}</span
        >条信息
        </div>
        <div class="op-extra">
          <icon-button
              v-if="hasRights('001007003001')"
              icon="add-select"
              @click.native="showAdd"
          />
          <icon-button
              v-if="hasRights('001007003002')"
              icon="batch"
              title="批量上传"
              @click.native="batchAdd({})"
          />
          <icon-button
              v-if="hasRights('001007003003')"
              icon="share"
              title="导出"
              @click.native="exportData"
          />
        </div>
      </div>

      <div>
        <el-table
            v-loading="isLoading"
            :data="dataList"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column fixed="left" label="车牌" width="150">
            <template slot-scope="scope">
              <span v-if="scope.row.subPlateNumber === ''"
              ><Numplate :type="scope.row.autoColor">{{
                  scope.row.autoId
                }}</Numplate></span
              >
              <span v-else
              ><Numplate
                  :type="scope.row.autoColor"
                  style="position: relative"
              >{{ scope.row.autoId }}</Numplate
              >
                <el-tooltip
                    :content="scope.row.subPlateNumber"
                    class="item"
                    effect="dark"
                    placement="right-end"
                >
                  <span class="more-car">多车</span>
                </el-tooltip>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="套餐类型" prop="type" width="120">
            <span>单车场</span>
          </el-table-column>
          <el-table-column label="车场名称" prop="parkingLotName" width="240">
          </el-table-column>
          <el-table-column label="绑定手机号" prop="phone" width="140">
          </el-table-column>
          <el-table-column label="套餐名称" prop="leaseTitle" width="300">
          </el-table-column>
          <el-table-column label="有效时段" width="240">
            <template slot-scope="scope">
              周一至周日
              <span>{{ scope.row.effectualTime === 1 ? "全天" : "" }}</span>
              <span v-if="scope.row.effectualTime === 0"
              >{{ scope.row.beginTime }}~{{ scope.row.endTime }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="生效时间"
              prop="beginDate"
              width="180"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.beginDate.substring(0, 11) }}</span>
            </template>
            <!-- <template slot-scope="scope">
              <span>{{
                dateFormat(scope.row.beginDate, "yyyy-mm-dd") + " 00:00:00"
              }}</span>
            </template> -->
          </el-table-column>
          <el-table-column
              align="center"
              label="到期时间"
              prop="endDate"
              width="180"
          >
            <template slot-scope="scope">
              <span>
                {{ scope.row.endDate.substring(0, 11) }}
              </span>
            </template>
            <!-- <template slot-scope="scope">
              <span>{{
                dateFormat(scope.row.endDate, "yyyy-mm-dd") + " 23:59:59"
              }}</span>
            </template> -->
          </el-table-column>
          <el-table-column label="创建时间" prop="createTime" width="180">
          </el-table-column>
          <el-table-column label="添加途径" prop="addWay" width="120">
            <template slot-scope="scope">
              <span>{{
                  scope.row.addWay === "0" ? "人工添加" : "小程序添加"
                }}</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="购买数量"
              prop="buyNumber"
              width="100"
          >
          </el-table-column>
          <el-table-column label="订单金额" prop="orderPrice" width="120">
            <template slot-scope="scope">
              {{ parseFloat(scope.row.orderPrice).toFixed(2) }}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="orderPrice" label="执行状态" width="120">
          </el-table-column> -->
          <el-table-column label="订单状态" prop="auditStatus" width="120">
            <template slot-scope="scope">
              <span>{{ auditStatus[scope.row.auditStatus] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="付款状态" width="120">
            <template slot-scope="scope">
              <span>{{ scope.row.payStatus === 0 ? "已付款" : "未付款" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="长租订单编号" prop="orderCode" width="300">
          </el-table-column>
          <el-table-column label="备注" prop="remark" width="220">
          </el-table-column>
          <!-- <el-table-column prop="remark" label="备注2" width="120">
          </el-table-column> -->
          <el-table-column
              align="center"
              fixed="right"
              label="操作"
              width="200"
          >
            <template slot-scope="scope">
              <!-- <el-button type="text" size="small" class="common-text" @click="goDetail(scope.row)">详情</el-button> -->
              <span>
                <el-button
                    v-if="
                    scope.row.auditStatus !== 4 && hasRights('001007003004')
                  "
                    class="common-text"
                    size="small"
                    type="text"
                    @click="keepLease(scope.row)"
                >续租</el-button
                >
                <el-button
                    v-if="
                    scope.row.auditStatus !== 4 && hasRights('001007003005')
                  "
                    class="common-text"
                    size="small"
                    type="text"
                    @click="doEdit(scope.row)"
                >编辑</el-button
                >
                <el-button
                    v-if="
                    scope.row.auditStatus !== 4 && hasRights('001007003006')
                  "
                    class="common-text"
                    size="small"
                    type="text"
                    @click="deleteLease(scope.row.id)"
                >停用</el-button
                >
                <el-button
                    v-if="hasRights('001007003007')"
                    class="common-text"
                    size="small"
                    type="text"
                    @click="doCopy(scope.row)"
                >复制</el-button
                >
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="pagination-box">
        <el-pagination
            :current-page="pagination.pageNo"
            :page-size="pagination.pageSize"
            :total="pagination.totalSize"
            background
            layout="sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
      </div>
    </div>

    <!-- 添加长租套餐弹窗 -->
    <el-dialog
        :before-close="beforeAddDialogClose"
        :visible.sync="addDialogVisible"
        append-to-body
        class="common-dialog"
        title="添加长租订单"
        width="1000px"
    >
      <el-form
          ref="model"
          v-loading="isAddLoading"
          :model="model"
          :rules="rules"
          label-width="100px"
          size="small"
      >
        <easy-card title="基础信息">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="选择车场：" prop="parkingLotId" required>
                <SuggestParking
                    v-model="model.parkingLotId"
                    style="width: 300px"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="选择套餐：" prop="leaseInfoId" required>
                <el-select
                    v-model="model.leaseInfoId"
                    class="select-inner"
                    placeholder="请选择-套餐"
                    style="width: 300px"
                    @change="chooseLease"
                >
                  <el-option
                      v-for="item in options"
                      :key="item.id"
                      :label="item.leaseTitle"
                      :value="{
                      value: item.id,
                      label: item.leaseTitle,
                      price: item.leasePrice,
                      longLeasePeriod: item.longLeasePeriod,
                    }"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                  label="主车牌："
                  prop="plate"
                  required
                  style="margin-bottom: 14px"
              >
                <div
                    class="xxd-input__wrap"
                    style="
                    padding: 0;
                    display: inline-block;
                    width: auto;
                    padding-right: 10px;
                    overflow: hidden;
                  "
                >
                  <el-select
                      v-model="model.vehicleColor"
                      placeholder="车牌颜色"
                  >
                    <el-option label="黄牌" value="01"></el-option>
                    <el-option label="蓝牌" value="02"></el-option>
                    <el-option label="新能源" value="03"></el-option>
                    <el-option label="黄绿牌" value="04"></el-option>
                    <el-option label="黑牌" value="06"></el-option>
                    <el-option label="白牌" value="23"></el-option>
                  </el-select>
                  <el-input
                      v-model="model.plate"
                      placeholder="主车牌号码"
                      style="width: 180px"
                  />
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-button
                  icon="el-icon-circle-plus-outline"
                  type="text"
                  @click="addSubPlate"
              ><span>点击添加副车牌</span></el-button
              >
            </el-col>
          </el-row>
          <el-row v-if="subPlateInput" :gutter="20">
            <el-col :span="12">
              <el-form-item
                  label="副车牌："
                  prop="subPlate"
                  style="margin-bottom: 14px"
              >
                <div
                    class="xxd-input__wrap"
                    style="
                    padding: 0;
                    display: inline-block;
                    width: auto;
                    padding-right: 10px;
                    overflow: hidden;
                  "
                >
                  <el-select
                      v-model="model.subVehicleColor"
                      placeholder="车牌颜色"
                  >
                    <el-option label="黄牌" value="01"></el-option>
                    <el-option label="蓝牌" value="02"></el-option>
                    <el-option label="新能源" value="03"></el-option>
                    <el-option label="黄绿牌" value="04"></el-option>
                    <el-option label="黑牌" value="06"></el-option>
                    <el-option label="白牌" value="23"></el-option>
                  </el-select>
                  <el-input
                      v-model="model.subPlate"
                      placeholder="副车牌号码"
                      style="width: 180px"
                  />
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-button
                  icon="el-icon-remove-outline"
                  type="text"
                  @click="deleteSubPlate"
              ><span>删除</span></el-button
              >
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="购买数量：" prop="payNumber" required>
                <el-input-number
                    v-model="model.payNumber"
                    :min="1"
                    controls-position="right"
                    style="width: 300px"
                    @change="buyNumber"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="生效日期：" prop="effectiveDate" required>
                <el-date-picker
                    v-model="model.effectiveDate"
                    placeholder="生效日期"
                    style="width: 180px"
                    type="date"
                    value-format="yyyy-MM-dd"
                    @change="effectDate"
                >
                </el-date-picker>
                <span
                    v-if="model.effectiveDate !== ''"
                    style="margin-left: 10px"
                >至</span
                ><span
                  v-if="model.effectiveDate !== ''"
                  style="margin-left: 10px"
              >{{ endEffectDate }}</span
              >
              </el-form-item>
            </el-col>
          </el-row>
        </easy-card>
        <easy-card title="选填信息">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="联系人：" prop="contact">
                <el-input
                    v-model="model.contact"
                    placeholder="联系人（选填）"
                    prefix-icon=""
                    style="width: 300px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话：" prop="phone">
                <el-input
                    v-model="model.phone"
                    placeholder="联系电话（选填）"
                    prefix-icon=""
                    style="width: 300px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="所属单位：" prop="unit">
                <el-input
                    v-model="model.unit"
                    placeholder="请填入-所属单位（选填）"
                    prefix-icon=""
                    style="width: 300px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="备注：" prop="remake">
                <el-input
                    v-model="model.remake"
                    maxlength="300"
                    placeholder="备注内容(字数限制300字以内)"
                    prefix-icon=""
                    show-word-limit
                    size="medium"
                    style="width: 760px"
                    type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </easy-card>
      </el-form>
      <el-row align="middle" justify="end" type="flex">
        <el-col :span="2">
          <span>订单金额</span>
        </el-col>
        <el-col :span="3">
          <el-card
              class="total-lease-amount"
              shadow="always"
              style="color: red; font-size: 30px"
          >
            {{
              model.leaseInfoId === ""
                  ? "0.00"
                  : model.leaseInfoId.price * model.payNumber
            }}
          </el-card>
          <div v-if="showPriceDetailed === true" style="margin-top: 10px">
            ({{ model.leaseInfoId.price }}元/{{
              model.leaseInfoId.longLeasePeriod === 0
                  ? "月"
                  : model.leaseInfoId.longLeasePeriod === 1
                      ? "季度"
                      : model.leaseInfoId.longLeasePeriod === 2
                          ? "半年"
                          : model.leaseInfoId.longLeasePeriod === 3
                              ? "全年"
                              : "天"
            }}*{{ model.payNumber }})
          </div>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="saveLeaseOrder">保存</el-button>
      </span>
    </el-dialog>

    <!-- 批量上传弹出框 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="uploadDialogFormVisible"
        class="common-dialog"
        title="批量上传"
        @close="onBatchUploadClosed"
    >
      <div
          class="button-area"
          style="margin-bottom: 16px; display: flex; flex-direction: row"
      >
        <SuggestParking
            v-model="batchModel.parkId"
            style="margin-right: 10px"
        />
        <el-select
            v-model="batchModel.leaseInfoId"
            class="select-inner"
            placeholder="请选择-长租套餐"
            style="width: 200px; margin-right: 10px"
        >
          <el-option
              v-for="(item, index) in batchModel.leaseOptions"
              :key="index"
              :label="item.leaseTitle"
              :value="item.id"
          >
          </el-option>
        </el-select>
        <el-link
            :underline="false"
            download="长租导入模板.xlsx"
            href="https://jingli-parking.oss-cn-qingdao.aliyuncs.com/content/lease_template.xlsx"
        >
          <el-button style="margin-right: 10px" type="primary"
          >下载模板
          </el-button
          >
        </el-link>
        <el-upload
            :before-upload="handleBeforeUpload"
            :http-request="doUpload"
            :show-file-list="false"
            accept=".xlsx"
            action=""
        >
          <el-button icon="el-icon-upload" type="success">上传文件</el-button>
        </el-upload>
        <div class="total-amount">
          上传总数量：<span style="color: #0768FD">{{
            batchModel.upLoadList.length
          }}</span>
        </div>
      </div>
      <div>
        <el-table
            v-loading="isUpLoadLoading"
            :data="batchModel.upLoadList"
            border
            class="common-table"
            height="400px"
            size="medium"
            style="width: 100%"
        >
          <el-table-column label="车牌号码" prop="plateNumber">
          </el-table-column>
          <el-table-column label="车牌颜色" prop="colorFriendly">
          </el-table-column>
          <el-table-column label="生效时间" prop="leaseStartDate" width="250">
          </el-table-column>
          <el-table-column label="购买数量" prop="purchaseNum">
          </el-table-column>
          <el-table-column label="联系电话" prop="contactNumber">
          </el-table-column>
          <el-table-column label="联系人" prop="contactPerson">
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="success" @click="save">保存</el-button>
        <el-button type="primary" @click="clean">清理</el-button>
        <el-button @click="cancelUpLoad">取消</el-button>
      </div>
    </el-dialog>

    <!--续租-->
    <el-dialog
        :visible.sync="keepLeaseDialogVisible"
        append-to-body
        class="common-dialog"
        title="续租"
        width="1000px"
    >
      <el-form
          ref="keepLeaseForm"
          v-loading="isKeepLeaseLoading"
          :model="keepLeaseModel"
          label-width="100px"
          size="small"
      >
        <easy-card title="基础信息">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="停车场名称：">
                <el-input v-model="keepLeaseModel.parkingLotName" disabled/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="套餐名称：">
                <el-input v-model="keepLeaseModel.leaseTitle" disabled/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="主车牌号码：">
                <el-input v-model="keepLeaseModel.autoPlateNumber" disabled/>
              </el-form-item>
            </el-col>
            <el-col v-if="keepLeaseModel.subAutoPlateNumber !== ''" :span="12">
              <el-form-item label="副车牌号码：">
                <el-input
                    v-model="keepLeaseModel.subAutoPlateNumber"
                    disabled
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="购买数量：" prop="buyNumber" required>
                <el-input-number
                    v-model="keepLeaseModel.buyNumber"
                    :min="1"
                    controls-position="right"
                    style="width: 300px"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="生效日期：" prop="endDate">
                <!-- <span>{{ keepLeaseModel.beginDate }}</span>
                <span style="margin: 0 10px">至</span>
                <span>{{ keepLeaseModel.endDate }}</span> -->
                <el-date-picker
                    v-model="keepLeaseModel.beginDate"
                    disabled
                    placeholder="开始日期"
                    style="width: 150px"
                    type="date"
                    value-format="yyyy-MM-dd"
                >
                </el-date-picker>
                <span style="margin: 0 10px">至</span>
                <el-date-picker
                    v-model="keepLeaseModel.endDate"
                    disabled
                    placeholder="到期日期"
                    style="width: 150px"
                    type="date"
                    value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </easy-card>
        <easy-card title="选填信息">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="联系人：" prop="contact">
                <el-input
                    v-model="keepLeaseModel.contact"
                    placeholder="联系人（选填）"
                    prefix-icon=""
                    style="width: 300px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话：" prop="phone">
                <el-input
                    v-model="keepLeaseModel.phone"
                    placeholder="联系电话（选填）"
                    prefix-icon=""
                    style="width: 300px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="所属单位：" prop="unit">
                <el-input
                    v-model="keepLeaseModel.unit"
                    placeholder="请填入-所属单位（选填）"
                    prefix-icon=""
                    style="width: 300px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="备注：" prop="remark">
                <el-input
                    v-model="keepLeaseModel.remark"
                    maxlength="300"
                    placeholder="备注内容(字数限制300字以内)"
                    prefix-icon=""
                    show-word-limit
                    size="medium"
                    style="width: 760px"
                    type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </easy-card>
        <el-row align="middle" justify="end" type="flex">
          <el-col :span="2">
            <span>订单金额</span>
          </el-col>
          <el-col :span="3">
            <el-card
                class="total-lease-amount"
                shadow="always"
                style="color: red; font-size: 30px"
            >
              {{
                keepLeaseModel.leaseInfoId === ""
                    ? "0.00"
                    : keepLeaseModel.price * keepLeaseModel.buyNumber
              }}
            </el-card>
            <div style="margin-top: 10px">
              ({{ keepLeaseModel.price }}元/{{
                keepLeaseModel.priceType === 0
                    ? "月"
                    : keepLeaseModel.priceType === 1
                        ? "季度"
                        : keepLeaseModel.priceType === 2
                            ? "半年"
                            : keepLeaseModel.priceType === 3
                                ? "全年"
                                : "天"
              }}*{{ keepLeaseModel.buyNumber }})
            </div>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="keepLeaseDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveKeepLeaseOrder">保存</el-button>
      </span>
    </el-dialog>

    <!-- 编辑 -->
    <el-dialog
        :visible.sync="editDialogVisible"
        append-to-body
        class="common-dialog"
        title="编辑"
        width="1000px"
    >
      <el-form
          ref="editModel"
          v-loading="isEditLoading"
          :model="editModel"
          :rules="editRules"
          label-width="100px"
          size="small"
      >
        <easy-card title="基础信息">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="停车场名称：">
                <el-input v-model="editModel.parkingLotName" disabled/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="套餐名称：">
                <el-input v-model="editModel.leaseTitle" disabled/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                  label="主车牌："
                  prop="plateNumber"
                  required
                  style="margin-bottom: 14px"
              >
                <div
                    class="xxd-input__wrap"
                    style="
                    padding: 0;
                    display: inline-block;
                    width: auto;
                    padding-right: 10px;
                    overflow: hidden;
                  "
                >
                  <el-select
                      v-model="editModel.plateColor"
                      placeholder="车牌颜色"
                  >
                    <el-option label="黄牌" value="01"></el-option>
                    <el-option label="蓝牌" value="02"></el-option>
                    <el-option label="新能源" value="03"></el-option>
                    <el-option label="黄绿牌" value="04"></el-option>
                    <el-option label="黑牌" value="06"></el-option>
                    <el-option label="白牌" value="23"></el-option>
                  </el-select>
                  <el-input
                      v-model="editModel.plateNumber"
                      placeholder="主车牌号码"
                      style="width: 180px"
                  />
                </div>
              </el-form-item>
              <!-- <el-form-item label="主车牌号码：">
                <el-input v-model="editModel.autoPlateNumber" />
              </el-form-item> -->
            </el-col>
            <el-col
                v-if="
                editModel.subPlateNumber === '' &&
                editSubPlate === false &&
                editModel.subPlateColor === ''
              "
                :span="12"
            >
              <el-button
                  icon="el-icon-circle-plus-outline"
                  type="text"
                  @click="editAddSubPlate"
              ><span>点击添加副车牌</span></el-button
              >
            </el-col>
          </el-row>
          <el-row
              v-if="
              editModel.subPlateNumber !== '' ||
              editSubPlate ||
              editModel.subPlateColor !== ''
            "
          >
            <el-col :span="12">
              <el-form-item
                  label="副车牌："
                  prop="subPlateNumber"
                  style="margin-bottom: 14px"
              >
                <div
                    class="xxd-input__wrap"
                    style="
                    padding: 0;
                    display: inline-block;
                    width: auto;
                    padding-right: 10px;
                    overflow: hidden;
                  "
                >
                  <el-select
                      v-model="editModel.subPlateColor"
                      placeholder="车牌颜色"
                  >
                    <el-option label="黄牌" value="01"></el-option>
                    <el-option label="蓝牌" value="02"></el-option>
                    <el-option label="新能源" value="03"></el-option>
                    <el-option label="黄绿牌" value="04"></el-option>
                    <el-option label="黑牌" value="06"></el-option>
                    <el-option label="白牌" value="23"></el-option>
                  </el-select>
                  <el-input
                      v-model="editModel.subPlateNumber"
                      placeholder="副车牌号码"
                      style="width: 180px"
                  />
                </div>
              </el-form-item>
              <!-- <el-form-item label="副车牌号码：">
                <el-input v-model="editModel.subAutoPlateNumber" />
              </el-form-item> -->
            </el-col>
            <el-col :span="12">
              <el-button
                  icon="el-icon-remove-outline"
                  type="text"
                  @click="editDeleteSubPlate"
              ><span>删除</span></el-button
              >
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="购买数量：" prop="buyNumber">
                <el-input-number
                    v-model="editModel.buyNumber"
                    :min="1"
                    controls-position="right"
                    disabled
                    style="width: 300px"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="生效日期：" prop="endDate">
                <span>{{ editModel.beginDate }}</span>
                <span style="margin: 0 10px">至</span>
                <span>{{ editModel.endDate }}</span>
                <!-- <el-date-picker
                  v-model="keepLeaseModel.endDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="截止日期"
                  style="width: 180px"
                >
                </el-date-picker> -->
              </el-form-item>
            </el-col>
          </el-row>
        </easy-card>
        <easy-card title="选填信息">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="联系人：" prop="contact">
                <el-input
                    v-model="editModel.contact"
                    placeholder="联系人（选填）"
                    prefix-icon=""
                    style="width: 300px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话：" prop="phone">
                <el-input
                    v-model="editModel.phone"
                    placeholder="联系电话（选填）"
                    prefix-icon=""
                    style="width: 300px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="所属单位：" prop="unit">
                <el-input
                    v-model="editModel.unit"
                    placeholder="请填入-所属单位（选填）"
                    prefix-icon=""
                    style="width: 300px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="备注：" prop="remark">
                <el-input
                    v-model="editModel.remark"
                    maxlength="300"
                    placeholder="备注内容(字数限制300字以内)"
                    prefix-icon=""
                    show-word-limit
                    size="medium"
                    style="width: 760px"
                    type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </easy-card>
        <el-row align="middle" justify="end" type="flex">
          <el-col :span="2">
            <span>订单金额</span>
          </el-col>
          <el-col :span="3">
            <el-card
                class="total-lease-amount"
                shadow="always"
                style="color: red; font-size: 30px"
            >
              {{
                editModel.leaseInfoId === ""
                    ? "0.00"
                    : editModel.price * editModel.buyNumber
              }}
            </el-card>
            <div style="margin-top: 10px">
              ({{ editModel.price }}元/{{
                editModel.priceType === 0
                    ? "月"
                    : editModel.priceType === 1
                        ? "季度"
                        : editModel.priceType === 2
                            ? "半年"
                            : editModel.priceType === 3
                                ? "全年"
                                : "天"
              }}*{{ editModel.buyNumber }})
            </div>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveEdit">保存</el-button>
      </span>
    </el-dialog>

    <!-- 复制订单 -->
    <el-dialog
        :visible.sync="copyDialogVisible"
        append-to-body
        class="common-dialog"
        title="编辑"
        width="1000px"
    >
      <el-form
          ref="copyModel"
          v-loading="isCopyLoading"
          :model="copyModel"
          :rules="copyRules"
          label-width="100px"
          size="small"
      >
        <easy-card title="基础信息">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="停车场名称：">
                <el-input v-model="copyModel.parkingLotName" disabled/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="套餐名称：">
                <el-input v-model="copyModel.leaseTitle" disabled/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                  label="主车牌："
                  prop="plate"
                  required
                  style="margin-bottom: 14px"
              >
                <div
                    class="xxd-input__wrap"
                    style="
                    padding: 0;
                    display: inline-block;
                    width: auto;
                    padding-right: 10px;
                    overflow: hidden;
                  "
                >
                  <el-select
                      v-model="copyModel.vehicleColor"
                      placeholder="车牌颜色"
                  >
                    <el-option label="黄牌" value="01"></el-option>
                    <el-option label="蓝牌" value="02"></el-option>
                    <el-option label="新能源" value="03"></el-option>
                    <el-option label="黄绿牌" value="04"></el-option>
                    <el-option label="黑牌" value="06"></el-option>
                    <el-option label="白牌" value="23"></el-option>
                  </el-select>
                  <el-input
                      v-model="copyModel.plate"
                      placeholder="主车牌号码"
                      style="width: 180px"
                  />
                </div>
              </el-form-item>
              <!-- <el-form-item label="主车牌号码：">
                <el-input v-model="editModel.autoPlateNumber" />
              </el-form-item> -->
            </el-col>
            <el-col
                v-if="
                copyModel.subPlate === '' &&
                copySubPlate === false &&
                copyModel.subVehicleColor === ''
              "
                :span="12"
            >
              <el-button
                  icon="el-icon-circle-plus-outline"
                  type="text"
                  @click="copyAddSubPlate"
              ><span>点击添加副车牌</span></el-button
              >
            </el-col>
          </el-row>
          <el-row
              v-if="
              copyModel.subPlate !== '' ||
              copySubPlate ||
              copyModel.subVehicleColor !== ''
            "
          >
            <el-col :span="12">
              <el-form-item
                  label="副车牌："
                  prop="subPlate"
                  style="margin-bottom: 14px"
              >
                <div
                    class="xxd-input__wrap"
                    style="
                    padding: 0;
                    display: inline-block;
                    width: auto;
                    padding-right: 10px;
                    overflow: hidden;
                  "
                >
                  <el-select
                      v-model="copyModel.subVehicleColor"
                      placeholder="车牌颜色"
                  >
                    <el-option label="黄牌" value="01"></el-option>
                    <el-option label="蓝牌" value="02"></el-option>
                    <el-option label="新能源" value="03"></el-option>
                    <el-option label="黄绿牌" value="04"></el-option>
                    <el-option label="黑牌" value="06"></el-option>
                    <el-option label="白牌" value="23"></el-option>
                  </el-select>
                  <el-input
                      v-model="copyModel.subPlate"
                      placeholder="副车牌号码"
                      style="width: 180px"
                  />
                </div>
              </el-form-item>
              <!-- <el-form-item label="副车牌号码：">
                <el-input v-model="editModel.subAutoPlateNumber" />
              </el-form-item> -->
            </el-col>
            <el-col :span="12">
              <el-button
                  icon="el-icon-remove-outline"
                  type="text"
                  @click="copyDeleteSubPlate"
              ><span>删除</span></el-button
              >
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="购买数量：" prop="payNumber">
                <el-input-number
                    v-model="copyModel.payNumber"
                    :min="1"
                    controls-position="right"
                    style="width: 300px"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="生效日期：" prop="endDate">
                <span>{{ copyModel.beginDate }}</span>
                <span style="margin: 0 10px">至</span>
                <span>{{ copyModel.endDate }}</span>
                <!-- <el-date-picker
                  v-model="keepLeaseModel.endDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="截止日期"
                  style="width: 180px"
                >
                </el-date-picker> -->
              </el-form-item>
            </el-col>
          </el-row>
        </easy-card>
        <easy-card title="选填信息">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="联系人：" prop="contact">
                <el-input
                    v-model="copyModel.contact"
                    placeholder="联系人（选填）"
                    prefix-icon=""
                    style="width: 300px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话：" prop="phone">
                <el-input
                    v-model="copyModel.phone"
                    placeholder="联系电话（选填）"
                    prefix-icon=""
                    style="width: 300px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="所属单位：" prop="unit">
                <el-input
                    v-model="copyModel.unit"
                    placeholder="请填入-所属单位（选填）"
                    prefix-icon=""
                    style="width: 300px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="备注：" prop="remark">
                <el-input
                    v-model="copyModel.remark"
                    maxlength="300"
                    placeholder="备注内容(字数限制300字以内)"
                    prefix-icon=""
                    show-word-limit
                    size="medium"
                    style="width: 760px"
                    type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </easy-card>
        <el-row align="middle" justify="end" type="flex">
          <el-col :span="2">
            <span>订单金额</span>
          </el-col>
          <el-col :span="3">
            <el-card
                class="total-lease-amount"
                shadow="always"
                style="color: red; font-size: 30px"
            >
              {{
                copyModel.leaseInfoId === ""
                    ? "0.00"
                    : copyModel.price * copyModel.payNumber
              }}
            </el-card>
            <div style="margin-top: 10px">
              ({{ copyModel.price }}元/{{
                copyModel.priceType === 0
                    ? "月"
                    : copyModel.priceType === 1
                        ? "季度"
                        : copyModel.priceType === 2
                            ? "半年"
                            : copyModel.priceType === 3
                                ? "全年"
                                : "天"
              }}*{{ copyModel.payNumber }})
            </div>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="copyDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveCopy">保存</el-button>
      </span>
    </el-dialog>

    <!-- 导出弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="exportDialogFormVisible"
        class="common-dialog"
        title="导出选项"
        width="600px"
    >
      <el-form ref="form" :model="form" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input
                v-model="form.exportSize"
                placeholder="请填写导出条数"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  batchImportLeaseList,
  createLeaseOrder,
  deleteLeaseOrderById,
  doUploadAnalysisExcel,
  getLeaseInfoByParkingLotId,
  getLeaseOrderById,
  getLeaseOrderForPage,
  leaseRenewalOrder,
  updateLeaseOrder,
} from "@/api/lease";
import BaseMixin from "@/mixins/base";
import Permission from "@/mixins/permission";
import SuggestParking from "@/components/SuggestParking";
import NumplateSuggest from "@/components/NumplateSuggest";
import Numplate from "@/components/Numplate";
import {doExport} from "@/api/common";

export default {
  name: "parking-order",
  mixins: [BaseMixin, Permission],
  components: {
    SuggestParking,
    NumplateSuggest,
    Numplate,
  },
  //监听选中的车场，查询当前车场的套餐
  watch: {
    "model.parkingLotId"(newVal) {
      if (newVal === "") {
        return;
      }
      getLeaseInfoByParkingLotId(newVal).then((res) => {
        if (res && res.code === 30 && res.result) {
          this.options = res.returnObject;
        }
      });
    },
    "batchModel.parkId"(newVal) {
      if (newVal === "") {
        return;
      }
      getLeaseInfoByParkingLotId(newVal).then((res) => {
        if (res && res.code === 30 && res.result) {
          this.batchModel.leaseOptions = res.returnObject;
          console.log(this.batchModel.leaseOptions);
        }
      });
    },
    "keepLeaseModel.buyNumber"(newVal) {
      var quantity = newVal;
      var priceType = this.keepLeaseModel.priceType; //当前套餐的生效周期
      var nowDate = this.keepLeaseModel.beginDate;
      if (nowDate === "") {
        return;
      }
      var date = new Date(nowDate);
      this.datePlusOrMinus(priceType, quantity, date); //调用日期加减函数
      var newDate = this.formatDate(date.getTime(), "YY-MM-DD");
      this.keepLeaseModel.endDate = newDate;
    },
    "copyModel.payNumber"(newVal) {
      var quantity = newVal;
      var priceType = this.copyModel.priceType; //当前套餐的生效周期
      var nowDate = this.copyModel.beginDate;
      if (nowDate === "") {
        return;
      }
      var date = new Date(nowDate);
      this.datePlusOrMinus(priceType, quantity, date); //调用日期加减函数
      var newDate = this.formatDate(date.getTime(), "YY-MM-DD");
      this.copyModel.endDate = newDate;
    },
  },
  data() {
    return {
      addDialogVisible: false, //添加长租订单弹窗
      uploadDialogFormVisible: false, //批量上传弹窗
      keepLeaseDialogVisible: false, // 续租
      showPriceDetailed: false, //是否展示订单金额明细
      total: 0,
      dataList: [], //返回的列表数据
      auditStatus: {
        0: "正常",
        1: "审核中",
        2: "审核不通过",
        3: "已过期",
        4: "已禁用",
        5: "待生效",
      },
      options: [], //根据车场查到的套餐选项
      endEffectDate: "", //计算出的结束时间
      isLoading: false,
      // 批量上传
      isUpLoadLoading: false,
      batchModel: {
        parkId: "",
        leaseInfoId: "",
        upLoadList: [],
        leaseOptions: [],
      },
      //续租数据
      isKeepLeaseLoading: false,
      keepLeaseModel: {
        id: "",
        parkingLotName: "",
        leaseTitle: "",
        autoPlateNumber: "",
        subAutoPlateNumber: "",
        leaseInfoId: "",
        buyNumber: 1,
        beginDate: "",
        endDate: "",
        remark: "",
        contact: "",
        phone: "",
        unit: "",
        priceType: "",
        price: "",
      },
      // keepLeaseRules: {
      //   endDate: [{ required: true, message: "截止时间不能为空" }],
      // },
      isAddLoading: false,
      model: {
        parkingLotId: "",
        leaseInfoId: "",
        vehicleColor: "02",
        plate: "",
        subVehicleColor: "",
        subPlate: "",
        payNumber: "",
        effectiveDate: "",
        phone: "",
        contact: "",
        unit: "",
        remake: "",
        parkingType: "1",
      },
      rules: {
        parkingLotId: [{required: true, message: "请选择车场"}],
        leaseInfoId: [{required: true, message: "请选择套餐"}],
        effectiveDate: [{required: true, message: "请选择生效开始日期"}],
        plate: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("主车牌号不能为空"));
              } else if (
                  !/^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[a-zA-Z](([a-zA-Z]((?![IO])[a-zA-Z0-9](?![IO]))[0-9]{4})|([0-9]{5}[a-zA-Z]))|[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1})$/.test(
                      value
                  )
              ) {
                callback(new Error("主车牌号码格式不正确"));
              } else {
                callback();
              }
            },
          },
        ],
        subPlate: [
          {
            required: false,
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("副车牌号不能为空"));
              } else if (
                  !/^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[a-zA-Z](([a-zA-Z]((?![IO])[a-zA-Z0-9](?![IO]))[0-9]{4})|([0-9]{5}[a-zA-Z]))|[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1})$/.test(
                      value
                  )
              ) {
                callback(new Error("副车牌号码格式不正确"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
      subPlateInput: false, //副车牌输入框
      query: {
        parkingLotId: "", // 车场ID
        phone: "", // 手机号码
        leaseInfoName: "", // 长租套餐
        status: "",
        auditStatus: "0",
        payStatus: "",
        addWay: "",
        vehicleId: "",
        leaseOrderId: "",
        code: "", //订单编号
        timeType: "0",
        searchDate: [],
        timeBegin: "",
        timeEnd: "",
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      },
      exportDialogFormVisible: false, //导出弹窗
      form: {
        exportSize: 1,
      },
      //编辑数据
      isEditLoading: false,
      editDialogVisible: false,
      editSubPlate: false,
      editModel: {
        id: "",
        plateColor: "02",
        plateNumber: "",
        subPlateNumber: "",
        subPlateColor: "02",
        remark: "",
        contact: "",
        phone: "",
        unit: "",
      },
      editRules: {
        plateNumber: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("主车牌号不能为空"));
              } else if (
                  !/^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[a-zA-Z](([a-zA-Z]((?![IO])[a-zA-Z0-9](?![IO]))[0-9]{4})|([0-9]{5}[a-zA-Z]))|[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1})$/.test(
                      value
                  )
              ) {
                callback(new Error("主车牌号码格式不正确"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        subPlateNumber: [
          {
            required: false,
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("副车牌号不能为空"));
              } else if (
                  !/^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[a-zA-Z](([a-zA-Z]((?![IO])[a-zA-Z0-9](?![IO]))[0-9]{4})|([0-9]{5}[a-zA-Z]))|[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1})$/.test(
                      value
                  )
              ) {
                callback(new Error("副车牌号码格式不正确"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },

      //复制订单数据
      isCopyLoading: false,
      copyDialogVisible: false,
      copySubPlate: false,
      copyModel: {
        // id: "",
        parkingLotId: "",
        leaseInfoId: "",
        vehicleColor: "02",
        plate: "",
        subPlate: "",
        subVehicleColor: "02",
        payNumber: "",
        effectiveDate: "",
        remark: "",
        contact: "",
        phone: "",
        unit: "",
        parkingType: "1",
        priceType: "",
        price: "",
      },
      copyRules: {
        plate: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("主车牌号不能为空"));
              } else if (
                  !/^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[a-zA-Z](([a-zA-Z]((?![IO])[a-zA-Z0-9](?![IO]))[0-9]{4})|([0-9]{5}[a-zA-Z]))|[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1})$/.test(
                      value
                  )
              ) {
                callback(new Error("主车牌号码格式不正确"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        subPlate: [
          {
            required: false,
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("副车牌号不能为空"));
              } else if (
                  !/^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[a-zA-Z](([a-zA-Z]((?![IO])[a-zA-Z0-9](?![IO]))[0-9]{4})|([0-9]{5}[a-zA-Z]))|[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1})$/.test(
                      value
                  )
              ) {
                callback(new Error("副车牌号码格式不正确"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = this.paramFormat(this.query);
      if (!params.searchDate || params.searchDate.length === 0) {
        params["timeType"] = "";
      }
      if (this.query.searchDate.length > 0) {
        params["timeBegin"] = this.query.searchDate[0];
        params["timeEnd"] = this.query.searchDate[1];
      }
      params["isExport"] = "1";
      params["exportSize"] = this.form.exportSize;
      // window.open("http://172.16.69.129:81" + "/leaseController/getLeaseOrderForPage?" + objectToQueryString(params));
      doExport(
          "/leaseController/getLeaseOrderForPage",
          params,
          "长租订单.xlsx"
      );
      loading.close();
      this.exportDialogFormVisible = false;
    },
    //编辑弹窗
    doEdit(row) {
      this.editModel = {
        id: row.id,
        parkingLotName: row.parkingLotName,
        leaseTitle: row.leaseTitle,
        leaseInfoId: row.leaseInfoId,
        buyNumber: row.buyNumber,
        plateNumber: row.autoId,
        plateColor: row.autoColor,
        subPlateNumber: row.subPlateNumber,
        subPlateColor: row.subAutoColor,
        beginDate: this.dateFormat(new Date(row.beginDate), "yyyy-mm-dd"),
        endDate: this.dateFormat(new Date(row.endDate), "yyyy-mm-dd"),
        remark: row.remark,
        contact: row.contactName,
        phone: row.contactTel,
        unit: row.unit,
        priceType: row.priceType,
        price: row.price,
      };
      this.editDialogVisible = true;
    },
    //编辑添加副车牌输入框
    editAddSubPlate() {
      this.editSubPlate = true;
      this.editModel.subPlateColor = "02";
      this.editModel.subPlateNumber = "";
    },
    //编辑删除副车牌输入框
    editDeleteSubPlate() {
      this.editModel.subPlateNumber = "";
      this.editModel.subPlateColor = "";
      this.editSubPlate = false;
    },
    //保存编辑
    saveEdit() {
      this.$refs.editModel.validate(async (valid) => {
        if (!valid) {
          return;
        }

        this.isEditLoading = true;
        const res = await updateLeaseOrder({
          id: this.editModel.id,
          plateColor: this.editModel.plateColor,
          plateNumber: this.editModel.plateNumber,
          subPlateNumber: this.editModel.subPlateNumber,
          subPlateColor: this.editModel.subPlateColor,
          contact: this.editModel.contact,
          phone: this.editModel.phone,
          unit: this.editModel.unit,
          remake: this.editModel.remark,
        });
        this.isEditLoading = false;
        if (res && res.code === 30 && res.result) {
          this.$message({
            message: "长租订单编辑成功",
            type: "success",
          });
          this.doSearch();
          this.editDialogVisible = false;
        }
      });
    },
    //复制订单弹窗
    doCopy(row) {
      this.copyModel = {
        // id: row.id,
        parkingLotName: row.parkingLotName,
        parkingLotId: row.parkingLotID,
        leaseTitle: row.leaseTitle,
        leaseInfoId: row.leaseInfoId,
        payNumber: row.buyNumber,
        plate: row.autoId,
        vehicleColor: row.autoColor,
        subPlate: row.subPlateNumber,
        subVehicleColor: row.subAutoColor,
        beginDate: this.dateFormat(new Date(row.beginDate), "yyyy-mm-dd"),
        endDate: this.dateFormat(new Date(row.endDate), "yyyy-mm-dd"),
        remark: row.remark,
        contact: row.contactName,
        phone: row.contactTel,
        unit: row.unit,
        priceType: row.priceType,
        price: row.price,
      };
      this.copyDialogVisible = true;
    },
    //复制订单添加副车牌输入框
    copyAddSubPlate() {
      this.copySubPlate = true;
      this.copyModel.subVehicleColor = "02";
      this.copyModel.subPlate = "";
    },
    //复制订单删除副车牌输入框
    copyDeleteSubPlate() {
      this.copyModel.subPlate = "";
      this.copyModel.subVehicleColor = "";
      this.copySubPlate = false;
    },
    //保存复制订单
    saveCopy() {
      this.$refs.copyModel.validate(async (valid) => {
        if (!valid) {
          return;
        }

        this.isCopyLoading = true;
        const res = await createLeaseOrder({
          parkingLotId: this.copyModel.parkingLotId,
          leaseInfoId: this.copyModel.leaseInfoId,
          vehicleColor: this.copyModel.vehicleColor,
          plate: this.copyModel.plate,
          subPlate: this.copyModel.subPlate,
          subVehicleColor: this.copyModel.subVehicleColor,
          payNumber: this.copyModel.payNumber,
          effectiveDate: this.copyModel.beginDate + " 00:00:00",
          contact: this.copyModel.contact,
          phone: this.copyModel.phone,
          unit: this.copyModel.unit,
          remake: this.copyModel.remark,
          parkingType: this.copyModel.parkingType,
        });
        this.isCopyLoading = false;
        if (res && res.code === 30 && res.result) {
          this.$message({
            message: "长租订单复制成功",
            type: "success",
          });
          this.doSearch();
          this.copyDialogVisible = false;
        }
      });
    },

    //点击添加副车牌
    addSubPlate() {
      this.model.subVehicleColor = "02";
      this.model.subPlate = "";
      this.subPlateInput = true;
    },
    //点击删除副车牌
    deleteSubPlate() {
      this.model.subVehicleColor = "";
      this.model.subPlate = "";
      this.subPlateInput = false;
    },

    /**
     * 关闭长租订单的创建弹窗回调
     * 关闭时重置表单
     */
    beforeAddDialogClose(done) {
      this.$refs.model.resetFields();
      done();
    },
    onBatchUploadClosed() {
      this.batchModel.parkId = "";
      this.batchModel.leaseInfoId = "";
      this.batchModel.upLoadList = [];
      this.batchModel.leaseOptions = [];
    },
    //点击续租--弹出弹窗
    keepLease(row) {
      this.keepLeaseModel = {
        id: row.id,
        parkingLotName: row.parkingLotName,
        leaseTitle: row.leaseTitle,
        leaseInfoId: row.leaseInfoId,
        buyNumber: 1,
        autoPlateNumber: row.autoId,
        subAutoPlateNumber: row.subPlateNumber,
        // beginDate: this.dateFormat(new Date(row.beginDate), "yyyy-mm-dd"),
        // endDate: new Date(row.endDate),
        beginDate: this.dateFormat(new Date(row.endDate), "yyyy-mm-dd"),
        endDate: null,
        remark: row.remark,
        contact: row.contactName,
        phone: row.contactTel,
        unit: row.unit,
        priceType: row.priceType,
        price: row.price,
      };
      var quantity = this.keepLeaseModel.buyNumber;
      var priceType = this.keepLeaseModel.priceType; //当前套餐的生效周期
      var nowDate = this.keepLeaseModel.beginDate;
      if (nowDate === "") {
        return;
      }
      var date = new Date(nowDate);
      this.datePlusOrMinus(priceType, quantity, date); //调用日期加减函数
      var newDate = this.formatDate(date.getTime(), "YY-MM-DD");
      this.keepLeaseModel.endDate = newDate;

      this.keepLeaseDialogVisible = true;
    },
    //保存续租
    saveKeepLeaseOrder() {
      this.$refs.keepLeaseForm.validate(async (valid) => {
        if (!valid) {
          return;
        }

        this.isKeepLeaseLoading = true;
        const res = await leaseRenewalOrder({
          id: this.keepLeaseModel.id,
          payNumber: this.keepLeaseModel.buyNumber,
          contact: this.keepLeaseModel.contact,
          phone: this.keepLeaseModel.phone,
          parkingSpace: "",
          unit: this.keepLeaseModel.unit,
          remake: this.keepLeaseModel.remark,
        });
        this.isKeepLeaseLoading = false;
        if (res && res.code === 30 && res.result) {
          this.$message({
            message: "长租订单续租成功",
            type: "success",
          });
          this.doSearch();
          this.keepLeaseDialogVisible = false;
        }
      });
    },
    async getLeaseOrderById(id) {
      const res = await getLeaseOrderById(id);
      if (res && res.code === 30 && res.result) {
        console.log(res);
      }
    },
    changeColor(id, text) {
      this.color_now = "type_0" + id;
      this.license = text;
    },
    //显示添加长租订单弹窗
    showAdd() {
      this.addDialogVisible = true;
    },
    //取消添加长租订单
    cancel() {
      this.addDialogVisible = false;
      this.$refs.model.resetFields();
      this.model.vehicleColor = "";
      this.model.plate = "";
      this.showPriceDetailed = false;
    },
    clean() {
      this.upLoadList = [];
    },
    //保存添加长租订单
    saveLeaseOrder() {
      this.$refs.model.validate((valid) => {
        if (valid) {
          // if (this.model.vehicleColor === "") {
          //   this.$message.error("请选择车牌颜色");
          //   return;
          // } else if (this.model.plate === "") {
          //   this.$message.error("请填写车牌号");
          //   return;
          // }
          // if(this.subPlateInput === true && this.model.subPlate === "") {
          //   this.$message.error("请填写副车牌或删除副车牌");
          //   return false;
          // }
          const data = {
            parkingLotId: this.model.parkingLotId,
            leaseInfoId: this.model.leaseInfoId.value,
            vehicleColor: this.model.vehicleColor,
            plate: this.model.plate,
            subVehicleColor: this.model.subVehicleColor,
            subPlate: this.model.subPlate,
            payNumber: this.model.payNumber,
            effectiveDate: this.model.effectiveDate + " 00:00:00",
            phone: this.model.phone,
            contact: this.model.contact,
            unit: this.model.unit,
            remake: this.model.remake,
            parkingType: this.model.parkingType,
          };
          this.isAddLoading = true;
          createLeaseOrder(data).then((res) => {
            this.isAddLoading = false;
            if (res && res.code === 30 && res.result) {
              this.$message({
                message: "添加长租订单成功",
                type: "success",
              });
              this.addDialogVisible = false;
              this.doSearch();
              this.$refs.model.resetFields(); //重置清空添加长租订单弹窗的内容
              this.model.subVehicleColor = "";
              // this.model.plate = "";
              this.showPriceDetailed = false;
              this.subPlateInput = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    //停用长租订单
    deleteLease(e) {
      this.$confirm("此操作将停用长租订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.isLoading = true;
        deleteLeaseOrderById(e).then((res) => {
          this.isLoading = false;
          if (res && res.code === 30 && res.result) {
            this.$message.success("停用订单成功！");
            this.doSearch();
          }
        });
      });
    },

    //批量添加
    batchAdd() {
      this.uploadDialogFormVisible = true;
    },
    //上传
    handleBeforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isExcel = testmsg === "xlsx";
      if (!isExcel) {
        this.$message({
          message: "只能上传.xlsx文件",
          type: "warning",
        });
        return false;
      } else {
        return true;
      }
    },
    async doUpload(file) {
      this.isUpLoadLoading = true;
      const formData = new FormData();
      formData.append("file", file.file);
      const res = await doUploadAnalysisExcel(formData);
      this.isUpLoadLoading = false;
      if (res && res.code === 30 && res.result) {
        this.batchModel.upLoadList = res.data.exportList;
      }
    },

    //保存模板数据
    save() {
      if (!this.batchModel.parkId) {
        this.$message({
          message: "请选择停车场",
          type: "warning",
        });
        return;
      }

      if (!this.batchModel.leaseInfoId) {
        this.$message({
          message: "请选择长租套餐",
          type: "warning",
        });
        return;
      }

      const params = JSON.stringify({
        parkId: this.batchModel.parkId,
        leaseInfoId: this.batchModel.leaseInfoId,
        dataList: this.batchModel.upLoadList,
      });

      batchImportLeaseList(params).then((res) => {
        if (res && res.code === 30 && res.result) {
          this.$message.success("保存成功！");
          this.uploadDialogFormVisible = false;
          this.batchModel.upLoadList = [];
          this.doSearch();
        }
      });
    },
    //取消上传
    cancelUpLoad() {
      this.uploadDialogFormVisible = false;
    },
    //查看长租订单详情
    goDetail(e) {
      this.$router.push({
        path: "/lease/lease_order_detail",
        query: {
          id: e.id,
        },
      });
    },
    //重置查询条件
    resetQueryForm() {
      this.$refs.query.resetFields();
      this.query.searchDate = [];
      this.doSearch();
    },

    //查询长租订单列表
    doSearch() {
      this.pagination.pageSize = 10;
      this.pagination.pageNo = 1;
      this.search();
    },
    async search() {
      this.isLoading = true;
      const params = this.paramFormat(this.query);

      if (!params.searchDate || params.searchDate.length === 0) {
        params["timeType"] = "";
      }

      params["timeBegin"] =
          params.searchDate && params.searchDate.length > 0
              ? params.searchDate[0]
              : "";
      params["timeEnd"] =
          params.searchDate && params.searchDate.length > 0
              ? params.searchDate[1]
              : "";

      const res = await getLeaseOrderForPage(params);
      this.isLoading = false;
      if (res && res.code === 30 && res.result) {
        const returnObject = res.returnObject;
        this.dataList = returnObject.records;
        this.pagination.totalSize = returnObject.total;
        this.pagination.totalPages = returnObject.pages;
        this.total = returnObject.total;
        this.form.exportSize = returnObject.total;
      }
    },

    //添加长租套餐--监听选择套餐改变
    chooseLease() {
      this.showPriceDetailed = true;
      this.model.effectiveDate = "";
      this.model.payNumber = "";
    },

    //监听日期更改
    effectDate() {
      var now = new Date(this.model.effectiveDate); //获取当前选择的时间
      var quantity = parseInt(this.model.payNumber); //输入的购买数量
      var priceType = this.model.leaseInfoId.longLeasePeriod; //当前套餐的生效周期
      this.datePlusOrMinus(priceType, quantity, now); //调用日期加减函数
      var newDate = this.formatDate(now.getTime(), "YY-MM-DD");
      this.endEffectDate = newDate;
    },

    //监听购买数量更改
    buyNumber() {
      var quantity = parseInt(this.model.payNumber); //输入的购买数量
      var priceType = this.model.leaseInfoId.longLeasePeriod; //当前套餐的生效周期
      var nowDate = this.model.effectiveDate;
      if (nowDate === "") {
        return;
      }
      var date = new Date(nowDate);
      this.datePlusOrMinus(priceType, quantity, date); //调用日期加减函数
      var newDate = this.formatDate(date.getTime(), "YY-MM-DD");
      this.endEffectDate = newDate;
    },

    //日期加减运算
    datePlusOrMinus(interval, number, date) {
      switch (interval) {
        case 3: {
          date.setFullYear(date.getFullYear() + number);
          return date;
        }
        case 1: {
          date.setMonth(date.getMonth() + number * 3);
          return date;
        }
        case 0: {
          date.setMonth(date.getMonth() + number);
          return date;
        }
        case 2: {
          date.setDate(date.getMonth() + number * 6);
          return date;
        }
        case 4: {
          date.setDate(date.getDate() + number);
          return date;
        }
        default: {
          break;
        }
      }
    },
    //  格式化日期
    formatDate(time, format) {
      var date = new Date(time);
      var year = date.getFullYear(),
          month = date.getMonth() + 1, //月份是从0开始的
          day = date.getDate(),
          hour = date.getHours(),
          min = date.getMinutes(),
          sec = date.getSeconds();
      var preArr = Array.apply(null, Array(10)).map(function (elem, index) {
        return "0" + index;
      }); // 开个长度为10的数组 格式为 00 01 02 03

      var newTime = format
          .replace(/YY/g, year)
          .replace(/MM/g, preArr[month] || month)
          .replace(/DD/g, preArr[day] || day)
          .replace(/hh/g, preArr[hour] || hour)
          .replace(/mm/g, preArr[min] || min)
          .replace(/ss/g, preArr[sec] || sec);

      return newTime;
    },
  },
  mounted() {
    this.doSearch();
  },
};
</script>

<style lang="less" scoped>
.autoNumber {
  display: flex;
  flex-direction: row;

  .numplate-color {
    width: 80px;
    height: 36px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;

    font &.type_02 {
      background: #0b6fc0;
    }

    &.type_01 {
      background: #e6b201;
    }

    &.type_03 {
      background: #0bc029;
    }

    &.type_04 {
      background: linear-gradient(90deg, #e6b201 42.8%, green 0, green);
    }

    &.type_06 {
      background: #333;
    }

    &.type_23 {
      background: #fff;
      color: #333;
    }
  }
}

.total-lease-amount {
  /deep/ .el-card__body {
    white-space: nowrap;
  }
}

.more-car {
  background-color: #f56c6c;
  border-radius: 10px;
  color: #fff;
  font-size: 12px;
  height: 18px;
  line-height: 18px;
  padding: 0 6px;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #fff;
  position: absolute;
  top: 2px;
  right: 30px;
}

.total-amount {
  font-size: 18px;
  margin-top: 12px;
  margin-left: 50px;
  color: #000000;
}
</style>
